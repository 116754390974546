<template>
   <div class="m-p">
      <Required_packages />
   </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
name: 'Required packages',
components: {
  Required_packages: defineAsyncComponent( () => import('@/components/Profile/Required_packages.vue') ),
},
}
</script>